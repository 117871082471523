import { createSlice } from "@reduxjs/toolkit";

var initial = {
  categorys: [],
  category: [],
  loading: false,
};

export const productsCategorySlice = createSlice({
  name: "products_category",
  initialState: initial,
  reducers: {
    loadCategorys: (state, action) => {
      state.categorys = action.payload;
    },
    addCategory: (state, action) => {
      state.categorys.push(action.payload);
    },

    deleteCategory: (state, action) => {
      var categorys = state.categorys;

      categorys.splice(action.payload, 1);

      state.categorys = categorys;
    },

    statusCategory: (state, action) => {
      var status;
      if (action.payload.status == 1) {
        status = 0;
      } else {
        status = 1;
      }
      state.categorys[action.payload.id].status = status;
    },
    updateCategory: (state, action) => {
      state.categorys[action.payload.id].name = action.payload.name;
    },
  },
});

export const {
  loadCategorys,
  addCategory,
  deleteCategory,
  statusCategory,
  updateCategory,
} = productsCategorySlice.actions;

export const selectCategory = (state) => state.products_category.categorys;

export default productsCategorySlice.reducer;
