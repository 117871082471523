import React from "react";
import { NavLink } from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import Wrapper from "../components/wrapper/Wrapper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import axios from "../api/axios";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

import Loader from "../components/Loader";
import swal from "sweetalert";

import { selectApplications } from "../features/applicationSlice";

import { selectUser } from "../features/userSlice";

import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  loadApplication,
  deleteApplication,
  statusApplication,
} from "../features/applicationSlice";

import {
 
  Button,
  Stack,
 
  Pagination,
} from "@mui/material";

export default function Applications() {
  const classes = useStyles();
  toast.configure();

  const user = useSelector(selectUser);
  const applications = useSelector(selectApplications);

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);

  const PageChangehandle = (value) => {
    setCurrentPage(value);
  };

   const handleStatus = (application_id, id, status) => {
     var status1;
     if (status === 1) {
       status1 = 0;
     } else {
       status1 = 1;
     }
     axios
       .post("/application/status/update", {
         id: application_id,
         token: user.token,
         status: status1,
       })
       .then(function (response) {
         if (response.data.error === false) {
           toast.success(response.data.message, { autoClose: 2000 });

           dispatch(statusApplication({ id: id, status: status }));
         } else {
           toast.warn(response.data.warning.name[0], { autoClose: 2000 });
         }
       })
       .catch(function (error) {
         toast.warn(error, { autoClose: 2000 });
       });
  };
  
   const handleDlete = (application_id, id) => {
     swal({
       title: "Are you sure?",
       text: "Do you want to delete this!",
       icon: "warning",
       buttons: true,
       dangerMode: true,
     }).then((willDelete) => {
       if (willDelete) {
         axios
           .post("/application/delete", {
             id: application_id,
             token: user.token,
           })
           .then(function (response) {
             if (response.data.error === false) {
               toast.success(response.data.message);

               dispatch(deleteApplication(id));
             } else {
               toast.warn(response.data.warning.name[0]);
             }
           })
           .catch(function (error) {
             toast.warn(error, { autoClose: 2000 });
           });
       }
     });
   };

  React.useEffect(() => {
    axios
      .post("list-application?page=" + currentPage, {
        token: user.token,
      })
      .then(function (response) {
        if (response.data.error === false) {
          dispatch(loadApplication(response.data.data));
          setLoading(false);
        } else {
        }
      })
      .catch(function (error) {
        return error;
      });
  }, [dispatch, currentPage]);
  return (
    <div>
      <SideBar />
      <Wrapper>
        

        {loading === false ? (
          <>
            <div className={classes.buttonHolder}>
              <span>Platforms</span>
              <Stack
                direction="row"
                spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                <NavLink
                  style={{ textDecoration: "none" }}
                  to="/create-application"
                >
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<AddIcon />}
                  >
                    Add App
                  </Button>
                </NavLink>
              </Stack>
            </div>

            {applications.data.length !== 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>Name</TableCell>

                        <TableCell>Platforms</TableCell>
                        <TableCell>User Flows</TableCell>

                        <TableCell>Products Category</TableCell>
                        <TableCell>Screenshots</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applications.data.map(function (item, i) {
                        return (
                          <TableRow
                            key={Math.floor(Date.now() / 1000) + i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell align="left">{item.product}</TableCell>
                            <TableCell align="left">{item.platform}</TableCell>

                            <TableCell align="left">{item.flows}</TableCell>
                           
                            <TableCell align="left">
                              {item.tag.map(function (tg, i) {
                                return (
                                  <span
                                    key={Math.floor(Date.now() / 1000) + i}
                                    className={classes.tag}
                                  >
                                    {tg.name}
                                  </span>
                                );
                              })}
                            </TableCell>
                            <TableCell align="left">
                              {item.screenshots}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                onClick={() => {
                                  handleStatus(item.id, i, item.status);
                                }}
                                variant="outlined"
                                color={item.status === 1 ? "success" : "error"}
                                startIcon={<VisibilityIcon />}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </Button>
                            </TableCell>

                            <TableCell align="right">
                              <NavLink
                                style={{ textDecoration: "none" }}
                               
                                to={'/applications/edit/'+item.id}
                              >
                                <Button
                                  variant="outlined"
                                  startIcon={<EditIcon />}
                                  style={{ marginRight: 10 }}
                                >
                                  Edit
                                </Button>
                              </NavLink>

                              <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  handleDlete(item.id, i);
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Stack spacing={2} style={{ marginTop: 22, float: "right" }}>
                  <Pagination
                    onChange={(e, value) => {
                      PageChangehandle(value);
                    }}
                    count={applications.last_page}
                    color="primary"
                  />
                </Stack>
              </>
            ) : (
              <p style={{ textAlign: "center" }}>NO data found</p>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Wrapper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonHolder: {
    boxShadow: "none",
    marginBottom: 20,
  },
  tag: {
    background: "#e6e6e6",
    padding: "6px",
    borderRadius: "16px",
    fontSize: "11px",
    margin: "4px",
  },
}));
