import React from "react";
import SideBar from "../components/sideBar/SideBar";
import Wrapper from "../components/wrapper/Wrapper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import axios from "../api/axios";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

import { selectCategory } from "../features/productsCategorySlice";

import { selectUser } from "../features/userSlice";

import { useDispatch } from "react-redux";
import {
  loadCategorys,
  addCategory,
  deleteCategory,
  statusCategory,
  updateCategory,
} from "../features/productsCategorySlice";
import Loader from "../components/Loader";
import swal from "sweetalert";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Stack,

  Box,
} from "@mui/material";

export default function ProductCategory() {
  toast.configure();
  const user = useSelector(selectUser);

  const categorys = useSelector(selectCategory);

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [activeEditCategory, setActiveEditCategory] = React.useState({
    name: null,
    cat_id: null,
    index_id: null,
  });

  const [addFormvalidation, setAddFormvalidation] = React.useState(false);

  const handleAddModalSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var category = data.get("category");
    var description = data.get("description");
    if (category.trim()) {
      axios
        .post("/product/categorys/create", {
          name: category.trim(),
          token: user.token,
          description: description.trim(),
        })
        .then(function (response) {
          if (response.data.error === false) {
            toast.success(response.data.message, { autoClose: 2000 });
            setOpenAddModal(false);
            dispatch(addCategory(response.data.data));
          } else {
            toast.warn(response.data.warning.name[0], { autoClose: 2000 });
          }
        })
        .catch(function (error) {
          toast.warn(error, { autoClose: 2000 });
        });
      setAddFormvalidation(false);
    } else {
      setAddFormvalidation(true);
    }
  };

  const handleEditModalSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var category = data.get("edit_category");
    var description = data.get("edit_description");
    var category_id = data.get("cat_id");
    var index_id = data.get("index_id");
    if (category.trim()) {
      axios
        .post("/product/categorys/update", {
          name: category.trim(),
          id: category_id,
          token: user.token,
          description: description.trim()
        })
        .then(function (response) {
          if (response.data.error === false) {
            toast.success(response.data.message, { autoClose: 2000 });
            setOpenEditModal(false);
            dispatch(updateCategory({ name: category, id: index_id }));
          } else {
            toast.warn(response.data.warning.name[0], { autoClose: 2000 });
          }
        })
        .catch(function (error) {
          toast.warn(error, { autoClose: 2000 });
        });
      setAddFormvalidation(false);
    } else {
      setAddFormvalidation(true);
    }
  };

  const handleStatus = (cat_id, id, status) => {

    var status1;
    if (status === 1) {
      status1 = 0;
    } else {
      status1 = 1;
    }
    axios
      .post("/product/categorys/status/update", {
        id: cat_id,
        token: user.token,
        status: status1,
      })
      .then(function (response) {
        if (response.data.error === false) {
          toast.success(response.data.message, { autoClose: 2000 });
          setOpenAddModal(false);
          dispatch(statusCategory({ id: id, status: status }));
        } else {
          toast.warn(response.data.warning.name[0], { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        toast.warn(error, { autoClose: 2000 });
      });
  };

  const handleDleteCategory = (cat_id, id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this category!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .post("/product/categorys/delete", {
            id: cat_id,
            token: user.token,
          })
          .then(function (response) {
            if (response.data.error === false) {
              toast.success(response.data.message);

              dispatch(deleteCategory(id));
            } else {
              toast.warn(response.data.warning.name[0]);
            }
          })
          .catch(function (error) {
            toast.warn(error, { autoClose: 2000 });
          });
      }
    });
  };
  const handleAddModalClickOpen = () => {
    setOpenAddModal(true);
  };

  const handleAddModalClose = () => {
    setOpenAddModal(false);
  };

  const handleEditModalClickOpen = (cat_id, id) => {
    setActiveEditCategory({
      name: categorys[id].name,
      description: categorys[id].description,
      cat_id: cat_id,
      id: id,
    });
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  React.useEffect(() => {
    axios
      .post("/product/categorys", {
     
        token: user.token,
      })
      .then(function (response) {
        if (response.data.error === false) {
          dispatch(loadCategorys(response.data.data));
          setLoading(false);
        } else {
        }
      })
      .catch(function (error) {
        return error;
      });
  }, [dispatch]);

  function AddCategoryModal() {
    return (
      <>
        <Dialog title="Category" modal="true" open={openAddModal}>
          <Box
            component="form"
            onSubmit={handleAddModalSubmit}
            Validate
            sx={{ mt: 1 ,width:480}}
          >
            <DialogTitle>Add Category</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="category"
                name="category"
                label="Category Name"
                type="text"
                fullWidth
                variant="standard"
              />
              <p
                style={{
                  color: "red",
                  fontSize: 10,
                  display: addFormvalidation ? "block" : "none",
                }}
              >
                Field cannot be empty
              </p>
            </DialogContent>

            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
              />
             
            </DialogContent>

            <DialogActions>
              <Button color="error" onClick={handleAddModalClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }

  function EditCategoryModal() {
    return (
      <>
        <Dialog title="Category" modal="true" open={openEditModal}>
          <Box
            component="form"
            onSubmit={handleEditModalSubmit}
            Validate
            sx={{ mt: 1, width: 480 }}
          >
            <DialogTitle>Edit Category</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="edit_category"
                name="edit_category"
                label="Category Name"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={activeEditCategory.name}
              />

              <TextField
                autoFocus
                margin="dense"
                id="edit_description"
                name="edit_description"
                label="Description"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={activeEditCategory.description}
              />
              <TextField
                id="cat_id"
                name="cat_id"
                type="hidden"
                style={{ opacity: 0 }}
                value={activeEditCategory.cat_id}
              />
              <TextField
                id="index_id"
                name="index_id"
                type="hidden"
                style={{ opacity: 0 }}
                value={activeEditCategory.id}
              />
              <p
                style={{
                  color: "red",
                  fontSize: 10,
                  display: addFormvalidation ? "block" : "none",
                }}
              >
                Field cannot be empty
              </p>
            </DialogContent>
            <DialogActions>
              <Button color="error" onClick={handleEditModalClose}>
                Cancel
              </Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }

  const classes = useStyles();

  return (
    <>
      <AddCategoryModal />
      <EditCategoryModal />

      <SideBar />
      <Wrapper>
        {loading === false ? (
          <>
            <div className={classes.buttonHolder}>
              <span>Products</span>
              <Stack
                direction="row"
                spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddModalClickOpen}
                  startIcon={<AddIcon />}
                >
                  Add Category
                </Button>
              </Stack>
            </div>

            {categorys.length ? (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Status</TableCell>

                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categorys.map(function (item, i) {
                        return (
                          <TableRow
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell align="left">{item.name}</TableCell>
                            <TableCell align="left">
                              {item.description}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                onClick={() => {
                                  handleStatus(item.id, i, item.status);
                                }}
                                variant="outlined"
                                color={item.status === 1 ? "success" : "error"}
                                startIcon={<VisibilityIcon />}
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </Button>
                            </TableCell>

                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                startIcon={<EditIcon />}
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  handleEditModalClickOpen(item.id, i);
                                }}
                              >
                                Edit
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  handleDleteCategory(item.id, i);
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ height: "40px" }}></div>
              </>
            ) : (
              <p style={{ textAlign: "center" }}>NO data found</p>
            )}
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </Wrapper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonHolder: {
    boxShadow: "none",
    marginBottom: 20,
  },
}));
