import React from "react";
import Wrapper from "../components/wrapper/Wrapper";

import SideBar from "../components/sideBar/SideBar";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";


import AnalyticsCard1 from "../components/home/AnalyticsCard1";
import AnalyticsCard2 from "../components/home/AnalyticsCard2";

export default function Home() {
  return (
    <div>
      <SideBar />
      <Wrapper>
        <Box sx={{ width: "100%", marginTop: 2 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticsCard2 title="Total Users" analytics="0" />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticsCard1 title="Payed Users" analytics="0" />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticsCard1 title="Inactive users" analytics="0" />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticsCard1 title="Total Revenue" analytics="0" />
            </Grid>
          </Grid>
        </Box>
      </Wrapper>
    </div>
  );
}
