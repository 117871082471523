import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";

import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
 
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,

} from "@mui/material";


export default function ImageCard({ title, image, cardColor,id, onHandleDelete }) {

  return (
    <Card style={{ margin: 10 }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: cardColor,
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt="green iguana"
          style={{ height: "217px", width: "auto", padding: "20px" }}
        />
      </div>
      <CardContent>
        <Typography
          style={{ fontSize: "15px" }}
          gutterBottom
          variant="h5"
          component="div"
        >
          {title}
        </Typography>
      </CardContent>
      <CardActions style={{ float: "right" }}>
        <Button variant="contained" color="error" size="small" onClick={()=>{onHandleDelete(id)}}>
          <DeleteIcon />
        </Button>
      </CardActions>
    </Card>
  );
}
