import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import ControlPointDuplicateTwoToneIcon from "@mui/icons-material/ControlPointDuplicateTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";

import Toolbar from "@mui/material/Toolbar";

import Header from "../header/Header";
import { NavLink } from "react-router-dom";

import CColors from "../theme";

import { Button } from "@mui/material";

import AdbIcon from '@mui/icons-material/Adb';


const drawerWidth = 240;

function SideBar(props) {
  const classes = useStyles();
 
 
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <img
          style={{ height: 44 }}
          src="https://www.gstatic.com/mobilesdk/160503_mobilesdk/logo/2x/firebase_28dp.png"
        />
        <span style={{ fontSize: 22, margin: 6 }}>Firebase</span>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button>
          <ListItemIcon sx={{ color: CColors.text }}>
            <GridViewTwoToneIcon sx={{ color: CColors.theam }} />
          </ListItemIcon>

          <NavLink className={classes.navLink}   to="/" >
            Dashboard
          </NavLink>
        </ListItem>

        <ListItem button>
          <ListItemIcon sx={{ color: CColors.text }}>
            <AdbIcon sx={{ color: 'red' }} />
          </ListItemIcon>

          <NavLink className={classes.navLink}   to="/applications" >
            <Button variant="contained" style={{background:'red'}} size="medium">
               Applications
             </Button>
          </NavLink>
        </ListItem>

          
      </List>
      <Divider />
      <List>
        <p className={classes.title}>Categorys</p>

        <ListItem button>
          <ListItemIcon>
            <ControlPointDuplicateTwoToneIcon sx={{ color: CColors.theam }} />
          </ListItemIcon>

          <NavLink  className={classes.navLink } to="/category/platform"  >
            Platforms
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ControlPointDuplicateTwoToneIcon sx={{ color: CColors.theam }} />
          </ListItemIcon>

          <NavLink  className={classes.navLink} to="/category/user-flows" sx={{ textDecoration: "none", color: CColors.text }}>
            User Flows
          </NavLink>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ControlPointDuplicateTwoToneIcon sx={{ color: CColors.theam }} />
          </ListItemIcon>

          <NavLink  className={classes.navLink} to="/category/products" sx={{ textDecoration: "none", color: CColors.text }}>
            Products
          </NavLink>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <ControlPointDuplicateTwoToneIcon sx={{ color: CColors.theam }} />
          </ListItemIcon>

          <NavLink  className={classes.navLink} to="/category/products-category" sx={{ textDecoration: "none", color: CColors.text }}>
            Product Categorys
          </NavLink>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} />

      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of NavLinks. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default SideBar;

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontWeight: 600,
    color: CColors.theam,
    fontSize: 12,
  },
  navLink: {
    textDecoration: 'none',
    color:'#707070'
  },
 
}));
