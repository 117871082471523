import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/userSlice";
import platformCategoryReducer from "../features/platformCategorySlice"
import flowsCategoryReducer from "../features/flowsCategorySlice"
import productsCategoyReducer from "../features/productsCategorySlice"
import productsDifCategoryReducer from "../features/productsDifCategorySlice"
import applicationsReducer from "../features/applicationSlice"

export default configureStore({
  reducer: {
    user: userReducer,
    platform_category: platformCategoryReducer,
    flows_category: flowsCategoryReducer,
    products_category: productsCategoyReducer,
    product_diff_category: productsDifCategoryReducer,
    application: applicationsReducer,
  },
});
