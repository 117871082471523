import React from 'react';
import { Box } from '@mui/system';

export default function Wrapper({ children }) {
  const drawerWidth = 240;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        paddingLeft: { xs: "0px", sm: "240px", md: "240px" },
        paddingTop: 0,
        marginX: 2,
        display: 'flex',
        padding:0
      }}
      style = {
        {
          height: '91vh',
          marginRight: 0,
          margin:0,
          background: 'rgb(227, 242, 253)',
          overflowY:'auto'
        }
      }
    >
      <div style={{margin:20,width:'100%'}}>
        {children}
        </div>
    
    </Box>
  );
}


