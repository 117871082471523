import React from 'react'
import { makeStyles } from '@mui/styles'

export default function OwalCard({children}) {
    const classes = useStyles();
  return (
      <>
          <div className={classes.root}>

              {children}

          </div>
      </>
  )
}

const useStyles = makeStyles((theam) => ({
    
    root: {
      background: "#ffff",
      borderRadius:' 20px',
      margin: '5px',
      padding: '10px',
  }
}))
