import React from "react";

import Box from "@mui/material/Box";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { AdjustSharp } from "@mui/icons-material";
import CColors from "../theme";

export default function AnalyticsCard2(props) {
  return (
    <Card>
      <CardContent sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: 1,
          }}
        >
          <AdjustSharp fontSize="large" sx={{ color:CColors.theam }} />
          <Typography
            sx={{ fontSize: "1.4545rem", fontWeight: 600, paddingLeft: "10px" }}
          >
            {props.analytics}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "0.875rem" }}
            color="text.secondary"
            gutterBottom
          >
            {props.title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
