import React, { useState } from "react";
import SideBar from "../components/sideBar/SideBar";
import Wrapper from "../components/wrapper/Wrapper";

import ReactPlayer from "react-player";

import { useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import axios from "../api/axios";

import DeleteIcon from "@mui/icons-material/Delete";

import Loader from "../components/Loader";
import swal from "sweetalert";
import { selectUser } from "../features/userSlice";
import OwalCard from "../components/Card/OwalCard";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImageCard from "../components/Application/ImageCard";
import { useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import ProgressBar from "../components/ProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import {
  TextField,
  IconButton,
  Box,
  Grid,
  Autocomplete,
  Checkbox,
  Paper,
  InputBase,
  Button,
  Typography,
  Stack,
  Switch,
} from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function EditApplication() {
  const classes = useStyles();
  toast.configure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(selectUser);
  const ref = React.createRef();

  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [trigger, setTrigger] = useState(null);

  const [apiData, setApiData] = useState(null);
  const [formData, setFormData] = useState(null);

  /* form datas */
  const [isPaid, setIsPaid] = useState(true);
  const [isMobileVideo, setIsMobileVideo] = useState(true);
  const [platform, setPlatform] = useState(null);
  const [platformError, setPlatformError] = useState(false);
  const [userFlow, setUserFlow] = useState(null);
  const [userFlowError, setUserFlowError] = useState(false);
  const [product, setProduct] = useState(null);
  const [productError, setProductError] = useState(false);
  const [productType, setProductType] = useState(null);
  const [productTypeError, setProductTypeError] = useState(false);
  let [productCategory, setProductCategory] = useState([]);
  const [productCategoryError, setProductCategoryError] = useState(false);

  const [timelineVideo, setTimelineVideo] = useState(null);
  const [timelineVideoUrl, setTimelineVideoUrl] = useState(null);
  const [videoType, setVideoType] = useState("portrait");

  const [playerPosition, setPlayerPosition] = useState(0);
  const [timeline, setTimeline] = useState([]);
  const [timelineTitle, setTimelineTitlt] = useState("");
  const [timelineErrorHandle, setTimelineErrorHandle] = useState(false);

  const [screenshot, setScreenshot] = useState([]);
  const [screenshotCurrentFile, setScreenshotCurrentFile] = useState(null);
  const [screenshotCurrentPath, setScreenshotCurrentPath] = useState();
  const [screenshotTitle, setScreenshotTitle] = React.useState("");
  const [screenshotErrorHandle, setScreenshotErrorHandle] = useState(false);

  const [screenshotColor, setScreenshotColor] = useState("#14FFB9");

  /* paid flow handler */

  const OnPaidFlowHandler = (e) => {
    setIsPaid(e.target.checked);
  };
  /* is mobile handler */
  const OnMobileVideoHandler = (e) => {
    setIsMobileVideo(e.target.checked);
  };
  /* platform handler */
  const OnPlatformHandler = (data) => {
    setPlatform(data.id);
  };

  /* userflow handler */
  const OnUserFlowHandler = (data) => {
    setUserFlow(data.id);
  };

  /* product handler */
  const OnProductHandler = (data) => {
    setProduct(data.id);
  };

  /* product type handler */

  const OnProductTypeHandler = (data) => {
    setProductType(data.name);
  };
  /* productCategory handler */
  const OnProductCategoryHandler = (data) => {
    setProductCategory(data);
  };

  /* screenshot section  */
  const screenshandler = () => {
    if (!screenshotTitle.trim()) {
      setScreenshotErrorHandle(true);
      return false;
    }

    if (screenshotCurrentFile === null) {
      toast.warn("image field cannot be empty");
      return false;
    }
    if (screenshotCurrentFile.type.includes("image") === true) {
      if (screenshotCurrentFile.size >= 100000) {
        toast.warn("Image too large max upload size 100kb");
        return false;
      } else {
        var data = {
          title: screenshotTitle.trim(),
          file: screenshotCurrentFile,
          imageUrl: URL.createObjectURL(screenshotCurrentFile),
        };

        var form = new FormData();
        form.append("img", data.file);
        form.append("title", data.title);
        form.append("application_id", formData.id);
        form.append("token", user.token);

        setIsImageUploading(true);

        axios
          .post("/application/upload/screenshot", form)
          .then(function (response) {
            if (response.data.error === false) {
              data["id"] = response.data.id;

              var screenshotdata = screenshot;

              screenshotdata.push(data);

              setScreenshot(screenshotdata);

              setScreenshotTitle("");
              setScreenshotErrorHandle(null);
              TriggerRender();
              toast.success("Added", { autoClose: 2000 });
              setIsImageUploading(false);
            } else {
              toast.warn(response.data.message, { autoClose: 2000 });
              setIsImageUploading(false);
            }
          })

          .catch(function (error) {
            setIsImageUploading(false);
            toast.warn(error, { autoClose: 2000 });
          });
      }
    } else {
      toast.warn("Invalid file");
      return false;
    }
  };

  const onHandleDelete = (id, screenshot_id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete this img!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .post("/application/delete/screenshot", {
            screenshot_id: screenshot_id,
            application_id: formData.id,
            token: user.token,
          })
          .then(function (response) {
            if (response.data.error === false) {
              var screenshotdata = screenshot;
              screenshotdata.splice(id, 1);
              setScreenshot(screenshotdata);
              TriggerRender();
              toast.success("Deleted", { autoClose: 2000 });
            } else {
              toast.warn(response.data.message, { autoClose: 2000 });
            }
          })

          .catch(function (error) {
            toast.warn(error, { autoClose: 2000 });
          });
      }
    });
  };

  /* end screenshot section */

  /* timeline section */

  const timelinehandler = () => {
    var seekTime = playerPosition;
    var title = timelineTitle.trim();
    if (title) {
      var data = {
        seekTime: seekTime,
        title: timelineTitle,
      };
      var timelineData = timeline;
      timelineData.push(data);
      setTimeline(timelineData);
      setTimelineTitlt("");
      setTimelineErrorHandle(false);
    } else {
      setTimelineErrorHandle(true);
    }
  };

  const deleteTimelineHanadle = (i) => {
    var timelinedata = timeline;
    timelinedata.splice(i, 1);
    setTimeline(timelinedata);
    TriggerRender();
  };

  const timelineVideoChangeHandler = (e) => {
    setTimelineVideo(e.target.files[0]);

    setTimelineVideoUrl(URL.createObjectURL(e.target.files[0]));
  };

  /* end timeline section */

  const TriggerRender = () => {
    setTrigger(new Date());
  };

  const SecondsToMinutes = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + parseInt(s);
  };

  const onSeekHandle = (e) => {
    setPlayerPosition(e);
  };

  /* progress config */

  let config = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  };

  /* form submit handler */

  const FormSubmitHandler = () => {
    setIsSaving(true);
    if (platform === null) {
      setPlatformError(true);
      setIsSaving(false);
      return false;
    }
    setPlatformError(false);

    if (userFlow === null) {
      setUserFlowError(true);
      setIsSaving(false);
      return false;
    }
    setUserFlowError(false);

    if (product === null) {
      setProductError(true);
      setIsSaving(false);
      return false;
    }
    setProductError(false);

    if (productType === null) {
      setProductTypeError(true);
      setIsSaving(false);
      return false;
    }
    setProductTypeError(false);

    if (productCategory.length === 0) {
      setProductCategoryError(true);
      setIsSaving(false);
      return false;
    }
    setProductCategoryError(false);

    var form = new FormData();
    form.append("paid", isPaid);
    form.append("isMobileVideo", isMobileVideo);
    form.append("platform", platform);
    form.append("user_flows", userFlow);
    form.append("product", product);
    form.append("application_type", productType);
    form.append("product_category", JSON.stringify(productCategory));

    form.append("video", timelineVideo);
    form.append("timeline", JSON.stringify(timeline));

    form.append("background_color", screenshotColor);
    form.append("token", user.token);
    form.append("application_id", formData.id);

    axios
      .post("/application/update", form, config)
      .then(function (response) {
        if (response.data.error === false) {
          toast.success("successfully Updated!", { autoClose: 2000 });
          setIsSaving(false);
        } else {
          setIsSaving(false);
          toast.warn(response.data.message, { autoClose: 2000 });
        }
      })

      .catch(function (error) {
        setIsSaving(false);
        toast.warn(error, { autoClose: 2000 });
      });
  };

  const LoadFormdata = (temp) => {
    axios
      .post("/application/edit/info", {
        id: id,
        token: user.token,
      })
      .then(function (response) {
        if (response.data.error === false) {
          setFormData(response.data.data);

          setIsPaid(response.data.data.paid === 1 ? true : false);
          setIsMobileVideo(
            response.data.data.device === "mobile" ? true : false
          );
          setPlatform(response.data.data.platform_id);
          setUserFlow(response.data.data.user_flows_id);
          setProduct(response.data.data.product_id);
          setProductType(response.data.data.application_type);
        
          setProductCategory(response.data.data.tag);
          setTimelineVideoUrl(response.data.data.video_url);
          setScreenshotColor(response.data.data.bg_color);

          setScreenshot(response.data.data.screenshots);

          setTimeline(JSON.parse(JSON.parse(response.data.data.timeline)));
          setLoading(false);
        } else {
          toast.warn(response.data.message, { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        toast.warn(error, { autoClose: 2000 });
      });
  };

  const Default_tag = () => {
    var data = [];

    for (var i = 0; i < apiData.product_category.length; i++) {
      for (var j = 0; j < productCategory.length; j++) {
        if (apiData.product_category[i].id === productCategory[j].id) {
          data.push(apiData.product_category[i]);
        }
      }
    }

    return data;
  };

  React.useEffect(() => {
    axios
      .post("/all-categorys", {
        token: user.token,
      })
      .then(function (response) {
        if (response.data.error === false) {
          setApiData(response.data.data);

          LoadFormdata(response.data.data);
        } else {
          toast.warn(response.data.message, { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        toast.warn(error, { autoClose: 2000 });
      });
  }, [dispatch]);

  return (
    <div>
      <SideBar />
      <Wrapper>
        {loading === false ? (
          <>
            <div className={classes.buttonHolder}>
              <span>Applicaton</span>
              <Stack
                direction="row"
                spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                {isSaving ? (
                  <div style={{ width: "20%", paddingTop: "9px" }}>
                    <ProgressBar progresspercentage={progress} />
                  </div>
                ) : (
                  <></>
                )}

                <LoadingButton
                  color="secondary"
                  onClick={() => {
                    FormSubmitHandler();
                  }}
                  style={{ background: "#5048E4" }}
                  loading={isSaving}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  Update
                </LoadingButton>
              </Stack>
            </div>
            <OwalCard>
              <Box>
                {/* dropdown section */}

                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Stack
                    style={{ margin: 25 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Typography>Free</Typography>
                    <Switch
                      checked={isPaid}
                      onChange={(e) => {
                        OnPaidFlowHandler(e);
                      }}
                      name="antoine"
                    />
                    <Typography>Paid</Typography>
                  </Stack>

                  <Stack
                    style={{ margin: 25 }}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Typography>Desktop</Typography>
                    <Switch
                      checked={isMobileVideo}
                      onChange={(e) => {
                        OnMobileVideoHandler(e);
                      }}
                      name="antoine"
                    />
                    <Typography>Mobile</Typography>
                  </Stack>
                </Box>
                <Grid container>
                  <Grid item={true} xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={apiData.platform}
                      getOptionLabel={(option) => option.name}
                      className={classes.dropdownField}
                      value={apiData.platform.find((v) => v.id === platform)}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Platform" />

                          {platformError ? (
                            <p className={classes.error}>
                              Platform Field cannot be empty
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        OnPlatformHandler(newValue);
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={apiData.flows}
                      getOptionLabel={(option) => option.name}
                      className={classes.dropdownField}
                      value={apiData.flows.find((v) => v.id === userFlow)}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="User Flow" />
                          {userFlowError ? (
                            <p className={classes.error}>
                              Flow Field cannot be empty
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        OnUserFlowHandler(newValue);
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={apiData.product}
                      getOptionLabel={(option) => option.name}
                      className={classes.dropdownField}
                      value={apiData.product.find((v) => v.id === product)}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Product" />
                          {productError ? (
                            <p className={classes.error}>
                              product Field cannot be empty
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        OnProductHandler(newValue);
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      options={apiData.application_type}
                      getOptionLabel={(option) => option.name}
                      className={classes.dropdownField}
                      value={apiData.application_type.find(
                        (v) => v.name === productType
                      )}
                      renderInput={(params) => (
                        <>
                          <TextField {...params} label="Platform Type" />
                          {productTypeError ? (
                            <p className={classes.error}>
                              product type Field cannot be empty
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        OnProductTypeHandler(newValue);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item={true} xs={12} md={12}>
                    <Autocomplete
                      multiple
                      options={apiData.product_category}
                      getOptionLabel={(option) => option.name}
                      disableCloseOnSelect
                      className={classes.dropdownField}
                      onChange={(event, newValue) => {
                        OnProductCategoryHandler(newValue);
                      }}
                      value={Default_tag()}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            label="Product Category"
                            placeholder="Movie"
                          />
                          {productCategoryError ? (
                            <p className={classes.error}>
                              product Category Field cannot be empty
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    />
                  </Grid>
                </Grid>

                {/* end dropdown section */}

                {/*  timeline section */}

                <Grid className={classes.timelineContainer} container>
                  {timelineVideoUrl ? (
                    <>
                      <Grid item={true} xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="success"
                          component="label"
                          style={{
                            width: "20px",
                            height: "40px",
                            position: "absolute",
                            background: "#2a2a2a",
                          }}
                        >
                          <CameraAltIcon />
                          <input
                            type="file"
                            onChange={(e) => {
                              timelineVideoChangeHandler(e);
                            }}
                            accept="video/*"
                            hidden
                          />
                        </Button>
                        <div className={classes.timelinePlayer}>
                          {/*   https://pageflows.com/media/videos/make_onb_actual.mov.mp4 */}
                          <ReactPlayer
                            ref={ref}
                            width={isMobileVideo ? "277px" : "960px"}
                            height={"400px"}
                            controls
                            url={timelineVideoUrl}
                            background={"#000"}
                            onProgress={(e) => {
                              onSeekHandle(e.playedSeconds);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item={true} xs={12} md={6}>
                        <div className={classes.timelineCard}>
                          <div>
                            <Paper
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>{SecondsToMinutes(playerPosition)}</span>

                              <InputBase
                                sx={{
                                  ml: 1,
                                  flex: 1,
                                  fontSize: 12,
                                  border:
                                    timelineErrorHandle === false
                                      ? "1px solid #f3ecec"
                                      : "1px solid red",
                                  padding: "3px",
                                  margin: "5px",
                                  borderRadius: "5px",
                                }}
                                value={timelineTitle}
                                placeholder="Add video timeline"
                                inputProps={{
                                  "aria-label": "search google maps",
                                }}
                                onChange={(e) => {
                                  setTimelineTitlt(e.target.value);
                                }}
                              />
                              <Button
                                onClick={(e) => {
                                  timelinehandler();
                                }}
                                variant="contained"
                              >
                                Add
                              </Button>
                            </Paper>
                            <Paper
                              sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                marginTop: "20px",
                              }}
                            >
                              <div className={classes.timelineFieldsGroup}>
                                {timeline.length !== 0 ? (
                                  <>
                                    {timeline.map(function (item, i) {
                                      return (
                                        <div
                                          key={i}
                                          onClick={() =>
                                            ref.current.seekTo(item.seekTime)
                                          }
                                          className={classes.timelinecardItem}
                                        >
                                          <span className={classes.timeBox}>
                                            {SecondsToMinutes(item.seekTime)}
                                          </span>
                                          &nbsp;&nbsp;
                                          <span style={{ fontSize: "12px" }}>
                                            {item.title}
                                          </span>
                                          <IconButton
                                            style={{
                                              position: "absolute",
                                              right: "10px",
                                            }}
                                            color="error"
                                            onClick={() => {
                                              deleteTimelineHanadle(i);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                        width: "100%",
                                        height: "38vh",
                                        color: "#878787",
                                      }}
                                    >
                                      <p>No timelines</p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </Paper>
                          </div>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          width: "100%",
                          height: "44vh",
                          color: "#878787",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="success"
                          component="label"
                        >
                          Upload video
                          <input
                            type="file"
                            onChange={(e) => {
                              timelineVideoChangeHandler(e);
                            }}
                            accept="video/*"
                            hidden
                          />
                        </Button>
                      </div>
                    </>
                  )}
                </Grid>
                {/*  end timeline section */}
                {/* sereenshot section  */}

                <div className={classes.screenshotContainer}>
                  <p style={{ textAlign: "center", padding: "5px" }}>
                    Screenshots
                  </p>
                  <input
                    onChange={(e) => {
                      setTimeout(function () {
                        setScreenshotColor(e.target.value);
                      }, 1000);
                    }}
                    type="color"
                    defaultValue={screenshotColor}
                    style={{ margin: "15px" }}
                  />

                  <Paper
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      margin: 5,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      component="label"
                    >
                      Upload Screenshot
                      <input
                        type="file"
                        onChange={(e) => {
                          setScreenshotCurrentFile(e.target.files[0]);
                          setScreenshotCurrentPath(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }}
                        accept="image/*"
                        hidden
                      />
                    </Button>

                    <img
                      style={{
                        maxWidth: "50px",
                        maxHeight: "50px",
                        margin: "10px",
                      }}
                      src={screenshotCurrentPath}
                    />

                    <InputBase
                      sx={{
                        ml: 1,
                        flex: 1,
                        fontSize: 12,
                        border:
                          screenshotErrorHandle === false
                            ? "1px solid #f3ecec"
                            : "1px solid red",
                        padding: "3px",
                        margin: "5px",
                        borderRadius: "5px",
                      }}
                      placeholder="Enter screenshot title"
                      inputProps={{ "aria-label": "Enter title" }}
                      onChange={(e) => {
                        setScreenshotTitle(e.target.value);
                      }}
                      value={screenshotTitle}
                    />

                    <LoadingButton
                      color="secondary"
                      onClick={() => {
                        screenshandler();
                      }}
                      style={{ background: "#5048E4" }}
                      loading={isImageUploading}
                      variant="contained"
                    >
                      {isImageUploading ? "Uploading..." : "Add"}
                    </LoadingButton>
                  </Paper>
                  <div
                    style={{
                      width: "100%",
                      minHeight: "48vh",
                      padding: "10px",
                      maxHeight: "auto",
                    }}
                  >
                    <Grid container style={{ width: "100%" }}>
                      {screenshot.length !== 0 ? (
                        <>
                          {screenshot.map(function (item, i) {
                            return (
                              <Grid
                                key={i}
                                item={true}
                                xs={12}
                                sm={12}
                                md={4}
                                lg={3}
                              >
                                <ImageCard
                                  title={item.title}
                                  image={item.imageUrl}
                                  cardColor={screenshotColor}
                                  id={i}
                                  onHandleDelete={() => {
                                    onHandleDelete(i, item.id);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              width: "100%",
                              height: "44vh",
                              color: "#878787",
                            }}
                          >
                            <p>No Screenshots</p>
                          </div>
                        </>
                      )}
                    </Grid>
                  </div>
                </div>

                {/* end screenshot section */}
              </Box>
            </OwalCard>
            <div style={{ height: "40px" }}></div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </Wrapper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dropdownField: {
    width: "100%",
    padding: "20px",
  },
  timelineContainer: {
    minHeight: "55vh",
    position:'relative'
  },
  timelinePlayer: {
    padding: "10px",
    width: "100%",
    height: "100%",
    overflow: "auto",
    border: " 10px solid black",
    background: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timelineCard: {
    padding: "10px",
    width: "100%",
    height: "100%",
    overflow: "auto",
    border: " 10px solid black",
    background: "#e3f2fd",
  },
  timelineFieldsGroup: {
    minHeight: "41vh",
    overflowY: "auto",
    width: "100%",
    padding: "10px",
    color: "white",
    maxHeight: "55vh",
  },
  timelinecardItem: {
    width: "100%",
    background: "#000000",
    padding: "8px",
    borderRadius: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    marginTop: "10px",
  },
  timeBox: {
    background: " #585656",
    padding: " 5px",
    borderRadius: "18px",
    fontSize: "12px",
  },
  screenshotContainer: {
    margin: "5px",
    marginTop: "20px",
    minHeight: "55vh",
    border: " 1px solid #e4e4e4",
    borderRadius: "8px",
  },
  buttonHolder: {
    boxShadow: "none",
    marginBottom: 20,
  },
  error: {
    fontSize: "9px",
    margin: "3px",
    color: "red",
  },
}));
