import { createSlice } from "@reduxjs/toolkit";






const initialAuthState =()=> {
  
  var local = localStorage.getItem('_user');
  var initial = {
    user: {
      isAuthenticated: false,
      token: null,
      name: null,
      email: null,
      profile: null,
    }
  }
  if (local == null) {
   
    localStorage.setItem("_user", JSON.stringify(initial));
    return initial;
  } else {
     
     let get_local_data = localStorage.getItem('_user');
    let parsed_local_data = JSON.parse(get_local_data);
    return parsed_local_data;
  
    
  };
}



export const userSlice = createSlice({
  name: "user",
  initialState: initialAuthState(),
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
       
       var userdata = {
         user:action.payload,
       };

      localStorage.setItem("_user", JSON.stringify(userdata));
    },
    logout: (state) => {
      state.user = {
        isAuthenticated: false,
        token: null,
        name: null,
        email: null,
        profile: null,
      };

      localStorage.removeItem("_user");
    },
  },
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
