import React from "react";
import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { selectUser } from "./features/userSlice";

const ProtectedRoute = ({ children }) => {
  const user = useSelector(selectUser);


  if (user) {
    if (!user.isAuthenticated) {
      return <Navigate to="/sign-in" />;
    } else {
      return children;
    }
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default ProtectedRoute;
