import React from 'react';

import Box from "@mui/material/Box";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CColors from '../theme';

export default function AnalyticsCard(props) {
    return (
      <Card>
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              sx={{ fontSize: "0.875rem" }}
              color="text.secondary"
              gutterBottom
            >
             {props.title}
            </Typography>

            <Typography sx={{ fontSize: "1.4545rem", fontWeight: 600 }}>
              {props.analytics}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AutoGraphIcon fontSize="large" sx={{color:CColors.theam}} />
          </Box>
        </CardContent>
      </Card>
    );
}
