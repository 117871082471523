import { createSlice } from "@reduxjs/toolkit";

var initial = {
  application: [],
};

export const applicationsSlice = createSlice({
  name: "application",
  initialState: initial,
  reducers: {
    loadApplication: (state, action) => {
      state.application = action.payload;
    },

    deleteApplication: (state, action) => {
      var application = state.application;

      application.data.splice(action.payload, 1);

      state.application = application;
    },

    statusApplication: (state, action) => {
      var status;
      if (action.payload.status == 1) {
        status = 0;
      } else {
        status = 1;
      }
      state.application.data[action.payload.id].status = status;
    },
  },
});

export const {
  loadApplication,

  deleteApplication,
  statusApplication,
} = applicationsSlice.actions;

export const selectApplications = (state) => state.application.application;

export default applicationsSlice.reducer;
