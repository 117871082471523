import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";



import Home from "./pages/Home";
import SignIn from "./pages/authentication/SignIn";
import PlatformCategory from "./pages/PlatformCategory";
import FlowsCategory from "./pages/FlowsCategory"
import ProductCategory from "./pages/ProductCategory";
import ProductDiffCategory from "./pages/ProductDiffCategory";
import Applications from "./pages/Applications";
import CreateApplication from "./pages/CreateApplication";
import EditApplication from "./pages/EditApplication";



function App() {
 

 
  return (
    <BrowserRouter>
      <Routes>

        
        <Route exact path="/sign-in" element={<SignIn />} />



        <Route exact path="/" element={<ProtectedRoute > <Home /> </ProtectedRoute>} />

        <Route exact path="/create-application" element={<ProtectedRoute > <CreateApplication /> </ProtectedRoute>} />
        <Route exact path="/applications" element={<ProtectedRoute > <Applications /> </ProtectedRoute>} />
        <Route exact path="/applications/edit/:id" element={<ProtectedRoute > <EditApplication /> </ProtectedRoute>} />
        

        <Route exact path="/category/platform" element={<ProtectedRoute > <PlatformCategory /> </ProtectedRoute>} />
        <Route exact path="/category/user-flows" element={<ProtectedRoute > <FlowsCategory /> </ProtectedRoute>} />
        <Route exact path="/category/products" element={<ProtectedRoute > <ProductCategory /> </ProtectedRoute>} />
        <Route exact path="/category/products-category" element={<ProtectedRoute > <ProductDiffCategory /> </ProtectedRoute>}  />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
